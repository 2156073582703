<template>
  <b10-base>
    <v-stepper
      vertical
      non-linear
      :value="currentStep"
      class="elevation-0"
    >
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.cliente.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.cliente.title }}
          <small>{{ steps.cliente.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.cliente.id"
        >
          <ValidationProvider
            v-if="idcliente"
            v-slot="{ errors }"
            name="cliente"
            :rules="formRules.cliente"
          >
            <b10-autocomplete
              v-model="form.cliente"
              :items="clientes"
              item-text="nombre"
              label="Cliente"
              clearable
              :error-messages="errors"
              :search-input.sync="clienteSearch"
              return-object
              no-filter
              @change="clienteSelected"
            >
              <template #item="slotProps">
                <v-list-item-content>
                  <v-list-item-title>{{ $online.cliente.title(slotProps.item) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $online.cliente.subtitle(slotProps.item) }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </b10-autocomplete>
          </ValidationProvider>
          <b10-autocomplete-alert
            v-if="form.cliente"
          >
            {{ $online.cliente.title(form.cliente) }}<br>{{ $online.cliente.subtitle(form.cliente) }}
          </b10-autocomplete-alert>
          <b10-autocomplete
            v-if="form.cliente"
            v-model="form.sistema"
            :items="sistemas"
            item-text="descripcion"
            label="Sistema"
            clearable
            return-object
            @change="sistemaSelected"
          >
            <template #item="slotProps">
              <v-list-item-content>
                <v-list-item-title>{{ $online.sistema.title(slotProps.item) }}</v-list-item-title>
                <v-list-item-subtitle>{{ $online.sistema.subtitle(slotProps.item) }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </b10-autocomplete>
          <v-alert
            v-if="form.sistema"
            type="info"
            dense
            :value="true"
          >
            {{ $online.sistema.title(form.sistema) }}<br>{{ $online.sistema.subtitle(form.sistema) }}
          </v-alert>
          <ValidationProvider
            v-if="idclientePotencial"
            v-slot="{ errors }"
            name="cliente_potencial"
            :rules="formRules.cliente_potencial"
          >
            <b10-autocomplete
              v-model="form.cliente_potencial"
              :items="clientesPotenciales"
              item-text="nombre"
              label="Cliente potencial"
              clearable
              :error-messages="errors"
              :search-input.sync="clientePotencialSearch"
              return-object
              no-filter
              @change="clientePotencialSelected"
            >
              <template #item="slotProps">
                <v-list-item-content>
                  <v-list-item-title>{{ $online.clientePotencial.title(slotProps.item) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $online.clientePotencial.subtitle(slotProps.item) }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </b10-autocomplete>
          </ValidationProvider>
          <b10-autocomplete-alert
            v-if="form.cliente_potencial"
          >
            {{ $online.clientePotencial.title(form.cliente_potencial) }}<br>{{ $online.clientePotencial.subtitle(form.cliente_potencial) }}
          </b10-autocomplete-alert>
          <ValidationProvider
            v-slot="{ errors }"
            name="cliente_direccion"
            :rules="formRules.cliente_direccion"
          >
            <b10-autocomplete
              v-model="form.cliente_direccion"
              :items="direcciones"
              item-text="descripcion"
              label="Dirección de servicio"
              clearable
              :error-messages="errors"
              return-object
              :disabled="!!form.sistema"
            >
              <template #item="slotProps">
                <v-list-item-content>
                  <v-list-item-title>{{ $online.clienteDireccion.title(slotProps.item) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $online.clienteDireccion.subtitle(slotProps.item) }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </b10-autocomplete>
          </ValidationProvider>
          <v-alert
            v-if="form.cliente_direccion"
            type="info"
            dense
            :value="true"
          >
            {{ $online.clienteDireccion.title(form.cliente_direccion) }}<br>{{ $online.clienteDireccion.subtitle(form.cliente_direccion) }}
          </v-alert>
          <v-text-field
            v-model="form.descripcion_sistema"
            clearable
            flat
            label="Nombre de la instalación/servicio"
            @change="changeDescripcionSistema"
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.datosGenerales.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.datosGenerales.title }}
          <small>{{ steps.datosGenerales.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.datosGenerales.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="fpresupuesto"
            :rules="formRules.fpresupuesto"
          >
            <b10-date-picker
              v-model="form.fpresupuesto"
              title="Fecha de presupuesto"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="tactuacion_tsistema"
            :rules="formRules.tactuacion_tsistema"
          >
            <b10-autocomplete
              v-model="form.tactuacion_tsistema"
              :items="tactuacionTsistemas"
              item-text="descripcion"
              label="Tipo de actuación/sistema"
              clearable
              :error-messages="errors"
              return-object
              @change="tactuacionTsistemaSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            name="incluir_plantilla"
          >
            <v-checkbox
              v-model="form.incluir_plantilla"
              label="Agregar una plantilla de artículos, cuotas y revisiones"
              @change="incluirPlantillaSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="idplantilla_articulo"
            :rules="formRules.idplantilla_articulo"
          >
            <b10-autocomplete
              v-model="form.idplantilla_articulo"
              :disabled="!form.incluir_plantilla"
              :items="plantillasArticulo"
              item-value="idplantilla_articulo"
              item-text="descripcion"
              label="Plantilla de artículos, cuotas y revisiones"
              clearable
              :error-messages="errors"
              @change="changePlantillaArticulo"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>

      <!-- Plantillas de artículos, cuotas o revisiones -->
      <div
        v-for="stepPlantilla in stepsPlantillas"
        :key="steps.datosGenerales.id + stepPlantilla.id"
      >
        <v-stepper-step
          :step="steps.datosGenerales.id + stepPlantilla.id"
          editable
        >
          {{ stepPlantilla.title }}
          <small>{{ stepPlantilla.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.datosGenerales.id + stepPlantilla.id"
        >
          <div
            v-if="stepPlantilla.formFields"
          >
            <template
              v-for="stepField in stepPlantilla.formFields"
            >
              <v-checkbox
                :key="stepField.idlplantilla"
                v-model="form[stepField.fieldnameLplantilla]"
                :label="stepField.title"
                :disabled="stepField.obligatorio"
                :persistent-hint="stepField.obligatorio"
                :hint="stepField.obligatorio ? 'Obligatorio' : ''"
              />
            </template>
          </div>
          <div
            v-else
          >
            <template
              v-for="group in stepPlantilla.groups"
            >
              <b10-subheader
                :key="group.idplantillaGrupoArticulo"
                :label="group.groupName"
                :icon="$vuetify.icons.values.articulo"
              />
              <template
                v-for="stepField in group.formFields"
              >
                <v-checkbox
                  :key="stepField.idlplantilla"
                  v-model="form[stepField.fieldnameLplantilla]"
                  :label="stepField.title"
                  :disabled="stepField.obligatorio"
                  :persistent-hint="stepField.obligatorio"
                  :hint="stepField.obligatorio ? 'Obligatorio' : ''"
                />
              </template>
            </template>
          </div>
        </v-stepper-content>
      </div>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.ficheros.id + stepsPlantillas.length"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.ficheros.title }}
          <small>{{ steps.ficheros.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.ficheros.id + stepsPlantillas.length"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="ficheros"
            :rules="formRules.ficheros"
          >
            <v-file-input
              v-model="form.ficheros"
              label="Ficheros"
              show-size
              prepend-icon="attach_file"
              chips
              multiple
              :accept="stringTypeExtensions"
              :append-outer-icon="$vuetify.icons.values.camera"
              :disabled="capturedImage"
              :error-messages="errors"
              @change="filesChange"
              @click:append-outer="clickCamera"
            />
          </ValidationProvider>
          <b10-camera
            :showing.sync="showingCamera"
            :device-id="devicesCameraDeviceId"
            @click-shutter="clickShutter"
            @change-deviceid="changeCamera"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="idtfichero"
            :rules="formRules.idtfichero"
          >
            <b10-autocomplete
              v-model="form.idtfichero"
              :items="tficheros"
              item-value="idtfichero"
              item-text="descripcion"
              label="Tipo de fichero"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.masDatos.id + stepsPlantillas.length"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.masDatos.title }}
          <small>{{ steps.masDatos.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.masDatos.id + stepsPlantillas.length"
        >
          <v-text-field
            v-model="form.titulo"
            clearable
            flat
            label="Título del presupuesto"
            :append-icon="form.titulo ? null : $vuetify.icons.values.equals"
            @click:append="clickCopyDescripcionSistemaATitulo"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="idfpago_sistema"
            :rules="formRules.idfpago_sistema"
          >
            <b10-autocomplete
              v-model="form.idfpago_sistema"
              :items="formasPago"
              item-value="idforma_pago"
              item-text="descripcion"
              label="Forma de pago"
              :error-messages="errors"
              clearable
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="idcondicion_pago"
            :rules="formRules.idcondicion_pago"
          >
            <b10-autocomplete
              v-model="form.idcondicion_pago"
              :items="condicionesPago"
              item-value="idcondicion_pago"
              item-text="descripcion"
              label="Condición de pago"
              :error-messages="errors"
              clearable
            />
          </ValidationProvider>
          <b10-autocomplete
            v-model="form.idfpago_cuotas"
            :items="formasPago"
            item-value="idforma_pago"
            item-text="descripcion"
            label="Forma de pago de cuotas"
            clearable
          />
          <b10-autocomplete
            v-model="form.idcondicion_pago_cuota"
            :items="condicionesPago"
            item-value="idcondicion_pago"
            item-text="descripcion"
            label="Condición de pago de cuotas"
            clearable
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="cliente_factura"
            :rules="formRules.cliente_factura"
          >
            <b10-autocomplete
              v-model="form.cliente_factura"
              :items="clientesFactura"
              item-text="nombre"
              label="Cliente de facturación"
              clearable
              :error-messages="errors"
              :search-input.sync="clienteFacturaSearch"
              return-object
              no-filter
              :messages="'Indicar solo si es diferente al cliente del presupuesto'"
            >
              <template #item="slotProps">
                <v-list-item-content>
                  <v-list-item-title>{{ $online.cliente.title(slotProps.item) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $online.cliente.subtitle(slotProps.item) }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </b10-autocomplete>
          </ValidationProvider>
          <b10-autocomplete-alert
            v-if="form.cliente_factura"
          >
            {{ $online.cliente.title(form.cliente_factura) }}<br>{{ $online.cliente.subtitle(form.cliente_factura) }}
          </b10-autocomplete-alert>
          <b10-autocomplete
            v-model="form.idagente_comercial"
            :items="agentesComerciales"
            item-value="idagente_comercial"
            item-text="nombre"
            label="Agente"
            clearable
          />
          <b10-autocomplete
            v-model="form.idcanal_comunicacion"
            :items="canalesComunicacion"
            item-value="idcanal_comunicacion"
            item-text="descripcion"
            label="Canal de comunicación"
            clearable
          />
          <b10-autocomplete
            v-model="form.idcanal_conocimiento"
            :items="canalesConocimiento"
            item-value="idcanal_conocimiento"
            item-text="descripcion"
            label="Canal de conocimiento"
            clearable
          />
          <b10-autocomplete
            v-model="form.tipo_riesgo"
            :items="tiposRiesgo"
            item-text="descripcion"
            label="Tipo de riesgo"
            return-object
            clearable
            @change="tipoRiesgoSelected"
          />
          <b10-autocomplete
            v-model="form.idgrado_seguridad"
            :items="gradosSeguridad"
            item-value="idgrado_seguridad"
            item-text="descripcion"
            label="Grado de seguridad"
            clearable
          />
          <b10-textarea
            v-model="form.observaciones"
            label="Observaciones"
          />
          <b10-textarea
            v-model="form.notas_internas"
            label="Notas internas"
          />
        </v-stepper-content>
      </ValidationObserver>
      <!-- Datos -->
      <tipo-dato-step-form
        v-show="idmtdato"
        :idmtdato="idmtdato"
        :form-step-id="maxStepID + 1"
        :validar-requeridos="false"
        @change-component-values="changeTipoDatoStepFormData"
      />
    </v-stepper>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import TipoDatoStepForm from '@/pages/components/TipoDatoStepForm'
import Data from './PresupuestocliAddFormData'
import _ from '@/utils/lodash'
import { currentDate } from '@/utils/date'
import { PARAMETRO, TDATO_TIPO_DATO, TYPE_EXTENSIONS } from '@/utils/consts'
import { sync } from 'vuex-pathify'
import  { extend } from 'vee-validate'
import { size as fileSizeValidation } from 'vee-validate/dist/rules'

export default {
  components: {
    TipoDatoStepForm
  },
  mixins: [formMixin],
  props: {
    idcliente: {
      type: [Number, String],
      default: null,
    },
    idclientePotencial: {
      type: [Number, String],
      default: null,
    },
    esTecnico: {
      type: Boolean,
    },
    esComercial: {
      type: Boolean,
    },
    esVigilante: {
      type: Boolean,
    },
  },
  data () {
    return {
      form: {
        // cliente
        cliente: null,
        sistema: null,
        cliente_potencial: null,
        cliente_direccion: null,
        descripcion_sistema: null,
        // datos generales
        fpresupuesto: currentDate(),
        tactuacion_tsistema: null,
        incluir_plantilla: true,
        idplantilla_articulo: null,
        // adjuntos
        ficheros: [],
        idtfichero: null,
        // más datos
        titulo: null,
        idfpago_sistema: null,
        idcondicion_pago: null,
        idfpago_cuotas: null,
        idcondicion_pago_cuota: null,
        cliente_factura: null,
        idagente_comercial: null,
        idcanal_comunicacion: null,
        idcanal_conocimiento: null,
        tipo_riesgo: null,
        idgrado_seguridad: null,
        observaciones: null,
        notas_internas: null,
      },
      formRules: {
        cliente: { required: true },
        sistema: { required: true },
        cliente_potencial: { required: true },
        cliente_direccion: { required: true },
        fpresupuesto: { required: true },
        tactuacion_tsistema: { required: true },
        idplantilla_articulo: { required_if: { target: 'incluir_plantilla' } },
        idtfichero: { required_if: { target: 'ficheros' } },
        ficheros: { tamanioFicheroAdjunto: true, tipoFicheroValido: true },
        idfpago_sistema: { required: true },
        idcondicion_pago: { required: true },
      },
      stringTypeExtensions: `${TYPE_EXTENSIONS.join(',')}`,
      steps: {
        cliente: {
          id: 1,
          title: 'Cliente',
          subtitle: 'Destinatario del presupuesto',
        },
        datosGenerales: {
          id: 2,
          title: 'Datos generales',
          subtitle: 'Datos generales y configuración del presupuesto',
        },
        ficheros: {
          id: 3,
          title: 'Adjuntos',
          subtitle: 'Ficheros adjuntos y fotografías',
        },
        masDatos: {
          id: 4,
          title: 'Más datos...',
          subtitle: 'Otros datos para crear el presupuesto',
        },
      },
      currentStep: 1,
      clientes: [],
      sistemas: [],
      direcciones: [],
      clientesFactura: [],
      clientesPotenciales: [],
      clienteSearch: '',
      clientePotencialSearch: '',
      clienteFacturaSearch: '',
      tactuacionTsistemas: [],
      plantillasArticulo: [],
      tficheros: [],
      formasPago: [],
      condicionesPago: [],
      agentesComerciales: [],
      canalesComunicacion: [],
      canalesConocimiento: [],
      tiposRiesgo: [],
      gradosSeguridad: [],
      TDATO_TIPO_DATO,
      capturedImage: false,
      showingCamera: false,
      idformaPagoDefecto: null,
      idcondicionPagoDefecto: null,
      tamanioMaxAdjuntoMB: 0,
      stepsPlantillas: [],
      datosPresupuestoCliente: [],
      idmtdato: null,
    }
  },
  computed: {
    devicesCameraDeviceId: sync('devices/cameraDeviceId'),
    maxStepID () {
      const maxStaticStepsID = Math.max(..._.map(this.steps, 'id'))
      return maxStaticStepsID + this.stepsPlantillas.length
    },
  },
  watch: {
    clienteSearch (value) {
      value && value.length >= 3 && value !== this.form.cliente && this.clienteDoSearch(value)
    },
    clientePotencialSearch (value) {
      value && value.length >= 3 && value !== this.form.cliente_potencial && this.clientePotencialDoSearch(value)
    },
    clienteFacturaSearch (value) {
      value && value.length >= 3 && value !== this.form.cliente_factura && this.clienteFacturaDoSearch(value)
    },
  },
  async created () {
    const parametrosNecesarios = [
      PARAMETRO.parametros.IDFORMA_PAGO_POR_DEFECTO,
      PARAMETRO.parametros.IDCONDICION_PAGO_POR_DEFECTO,
      PARAMETRO.parametros.IDAGENTE_COMERCIAL_POR_DEFECTO,
      PARAMETRO.parametros.TAMANIO_MAX_ADJUNTOS_MB,
    ]
    const resp = await Data.selectLookups(this, parametrosNecesarios, this.esTecnico, this.esComercial, this.esVigilante)
    const parametroMaxAdjuntoMB = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TAMANIO_MAX_ADJUNTOS_MB })
    if (parametroMaxAdjuntoMB) {
      this.tamanioMaxAdjuntoMB = parseInt(parametroMaxAdjuntoMB.valor)
    }
    extend('tamanioFicheroAdjunto', {
      message: 'El fichero es demasiado grande',
      validate: async (value) => {
        if (this.tamanioMaxAdjuntoMB > 0) {
          return fileSizeValidation.validate(value, { size: this.tamanioMaxAdjuntoMB * 1024 })
        } else {
          return true
        }
      }
    })
    // Validación para el tipo de fichero
    extend('tipoFicheroValido', {
      message: 'Tipo de fichero no permitido',
      validate: (value) => {
        return _.filter(value, (fileValue) => !TYPE_EXTENSIONS.includes(fileValue.type)).length === 0
      }
    })
    this.tactuacionTsistemas = resp.data.selectTactuacionTsistema.result.dataset
    this.plantillasArticulo = resp.data.selectPlantillaArticulo.result.dataset
    this.tficheros = resp.data.selectTfichero.result.dataset
    this.formasPago = resp.data.selectFormaPago.result.dataset
    this.condicionesPago = resp.data.selectCondicionPago.result.dataset
    this.agentesComerciales = resp.data.selectAgenteComercial.result.dataset
    this.canalesComunicacion = resp.data.selectCanalComunicacion.result.dataset
    this.canalesConocimiento = resp.data.selectCanalConocimiento.result.dataset
    this.tiposRiesgo = resp.data.selectTipoRiesgo.result.dataset
    this.gradosSeguridad = resp.data.selectGradoSeguridad.result.dataset
    // valores por defecto
    const parametroFormaPagoDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.IDFORMA_PAGO_POR_DEFECTO })
    if (parametroFormaPagoDefecto) {
      const existeFormaPagoDefecto = _.find(this.formasPago, { idforma_pago: parseInt(parametroFormaPagoDefecto.valor) })
      if (existeFormaPagoDefecto) {
        this.idformaPagoDefecto = parseInt(parametroFormaPagoDefecto.valor)
      }
    }
    const parametroCondicionPagoDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.IDCONDICION_PAGO_POR_DEFECTO })
    if (parametroCondicionPagoDefecto) {
      const existeCondicionPagoDefecto = _.find(this.condicionesPago, { idcondicion_pago: parseInt(parametroCondicionPagoDefecto.valor) })
      if (existeCondicionPagoDefecto) {
        this.idcondicionPagoDefecto = parseInt(parametroCondicionPagoDefecto.valor)
      }
    }
    const parametroAgenteDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.IDAGENTE_COMERCIAL_POR_DEFECTO })
    if (parametroAgenteDefecto) {
      const existeAgenteDefecto = _.find(this.agentesComerciales, { idagente_comercial: parseInt(parametroAgenteDefecto.valor) })
      if (existeAgenteDefecto) {
        this.$set(this.form, 'idagente_comercial', parseInt(parametroAgenteDefecto.valor))
      }
    }
    this.asignarCondicionesPagoDefecto()
    if (this.idcliente) {
      const cliente = await Data.selectCliente(this, this.idcliente)
      this.clientes = [cliente]
      this.$set(this.form, 'cliente', cliente)
      // forzar evento
      this.clienteSelected(cliente)
    } else if (this.idclientePotencial) {
      const clientePotencial = await Data.selectClientePotencial(this, this.idclientePotencial)
      this.clientesPotenciales = [clientePotencial]
      this.$set(this.form, 'cliente_potencial', clientePotencial)
      // forzar evento
      this.clientePotencialSelected(clientePotencial)
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    async clienteDoSearch (value) {
      this.clientes = await Data.selectClienteSearch(this, value)
    },
    async clientePotencialDoSearch (value) {
      this.clientesPotenciales = await Data.selectClientePotencialSearch(this, value)
    },
    async clienteFacturaDoSearch (value) {
      this.clientesFactura = await Data.selectClienteSearch(this, value)
    },
    asignarCondicionesPagoDefecto () {
      this.$set(this.form, 'idfpago_sistema', this.idformaPagoDefecto)
      this.$set(this.form, 'idcondicion_pago', this.idcondicionPagoDefecto)
      this.$set(this.form, 'idfpago_cuotas', this.idformaPagoDefecto)
      this.$set(this.form, 'idcondicion_pago_cuota', this.idcondicionPagoDefecto)
    },
    async clienteSelected (value) {
      if (value) {
        const selectLookupsCliente = await Data.selectLookupsCliente(this, value.idcliente)
        this.sistemas = selectLookupsCliente.data.selectSistema.result.dataset
        this.direcciones = selectLookupsCliente.data.selectDireccion.result.dataset
        if (this.sistemas.length === 1) {
          const sistema = this.sistemas[0]
          this.$set(this.form, 'sistema', sistema)
          // forzar evento
          this.sistemaSelected(sistema)
        }
        this.$set(this.form, 'idcanal_conocimiento', value.idcanal_conocimiento)
        if (value.idagente_comercial){
          this.$set(this.form, 'idagente_comercial', value.idagente_comercial)
        }
      } else {
        this.sistemas = []
        this.direcciones = []
        this.$set(this.form, 'sistema', null)
        this.$set(this.form, 'cliente_direccion', null)
        this.$set(this.form, 'idcanal_conocimiento', null)
      }
    },
    async sistemaSelected (value) {
      if (value) {
        this.$set(this.form, 'descripcion_sistema', value.descripcion)
        const direccionSistema = await Data.selectClienteDireccion(this, value.idcliente_direccion_sist)
        this.$set(this.form, 'cliente_direccion', direccionSistema)
        if (value.idforma_pago) {
          this.$set(this.form, 'idfpago_sistema', value.idforma_pago)
        }
        if (value.idcondicion_pago) {
          this.$set(this.form, 'idcondicion_pago', value.idcondicion_pago)
        }
        if (value.idformapago_cuota) {
          this.$set(this.form, 'idfpago_cuotas', value.idformapago_cuota)
        }
        if (value.idcondicion_pago_cuota) {
          this.$set(this.form, 'idcondicion_pago_cuota', value.idcondicion_pago_cuota)
        }
      } else {
        this.$set(this.form, 'descripcion_sistema', null)
        this.$set(this.form, 'cliente_direccion', null)
        this.asignarCondicionesPagoDefecto()
      }
    },
    async clientePotencialSelected (value) {
      if (value) {
        const selectLookupsClientePotencial = await Data.selectLookupsClientePotencial(this, value.idcliente_potencial)
        this.direcciones = selectLookupsClientePotencial.data.selectDireccion.result.dataset
        if (value.idcliente_direccion_servicio) {
          const direccionServicio = await Data.selectClienteDireccion(this, value.idcliente_direccion_servicio)
          this.$set(this.form, 'cliente_direccion', direccionServicio)
        } else {
          this.$set(this.form, 'cliente_direccion', null)
        }
        this.$set(this.form, 'idcanal_conocimiento', value.idcanal_conocimiento)
      } else {
        this.direcciones = []
        this.$set(this.form, 'cliente_direccion', null)
        this.$set(this.form, 'idcanal_conocimiento', null)
      }
    },
    tactuacionTsistemaSelected (value) {
      this.idmtdato = value.idmtdato_presupuesto
    },
    changeDescripcionSistema () {
      if (!this.form.titulo) {
        this.clickCopyDescripcionSistemaATitulo()
      }
    },
    clickCopyDescripcionSistemaATitulo () {
      this.$set(this.form, 'titulo', this.form.descripcion_sistema)
    },
    filesChange (files) {
      this.$set(this.form, 'ficheros', files)
    },
    clickCamera () {
      this.showingCamera = true
    },
    changeCamera (value) {
      this.devicesCameraDeviceId = value
    },
    clickShutter (imgFile) {
      this.filesChange([imgFile])
      this.capturedImage = true
    },
    tipoRiesgoSelected (value) {
      if (value) {
        this.$set(this.form, 'idgrado_seguridad', value.idgrado_seguridad)
      } else {
        this.$set(this.form, 'idgrado_seguridad', null)
      }
    },
    incluirPlantillaSelected (value) {
      if (!value) {
        this.$set(this.form, 'idplantilla_articulo', null)
        this.borrarPlantillaFormFields()
      }
    },
    borrarFormFields (step) {
      for (const stepField of step.formFields) {
        delete this.form[stepField.fieldnameLplantilla]
      }
    },
    borrarPlantillaFormFields () {
      // borrar campos del form de las plantillas
      for (const stepPlantilla of this.stepsPlantillas) {
        if (stepPlantilla.formFields) {
          this.borrarFormFields(stepPlantilla)
        } else {
          for (const group of stepPlantilla.groups) {
            this.borrarFormFields(group)
          }
        }
      }
      this.stepsPlantillas = []
    },
    async changePlantillaArticulo (value) {
      this.borrarPlantillaFormFields()
      const resp = await this.$online.plantillaArticulo.selectLineasPlantilla(value)
      let linea = {}
      let step = {}
      let stepId = 0
      const lplantillaArticulo = resp.data.selectLplantillaArticulo.result.dataset
      if (lplantillaArticulo.length > 0 ) {
        stepId = stepId + 1
        step = {
          id: stepId,
          title: 'Artículos',
          subtitle: 'Artículos incluidos en el presupuesto',
          groups: [],
        }
        this.stepsPlantillas.push(step)
        let lastLinea = { idlplantillaArticulo: -1 }
        let lastGrupo = { idplantillaGrupoArticulo: -1 }
        for (const lineaPlantillaArticulo of lplantillaArticulo) {
          if (lineaPlantillaArticulo.idplantilla_grupo_articulo !== lastGrupo.idplantillaGrupoArticulo){
            lastGrupo = {
              idplantillaGrupoArticulo: lineaPlantillaArticulo.idplantilla_grupo_articulo,
              groupName: lineaPlantillaArticulo.grupo_articulo_desc,
              formFields: []
            }
            step.groups.push(lastGrupo)
          }
          if (lineaPlantillaArticulo.idlplantilla_articulo !== lastLinea.idlplantillaArticulo) {
            const fieldnameLplantillaArticulo = `lplantilla_articulo_${lineaPlantillaArticulo.idlplantilla_articulo}`
            lastLinea = {
              idlplantilla: lineaPlantillaArticulo.idlplantilla_articulo,
              incluir: lineaPlantillaArticulo.incluir_por_defecto,
              obligatorio: lineaPlantillaArticulo.obligatorio,
              fieldnameLplantilla: fieldnameLplantillaArticulo,
              title: this.$online.lplantillaArticulo.title(lineaPlantillaArticulo),
            }
            lastGrupo.formFields.push(lastLinea)
            this.$set(this.form, fieldnameLplantillaArticulo, lineaPlantillaArticulo.incluir_por_defecto)
          }
        }
      }
      const lplantillaCuota = resp.data.selectLplantillaCuota.result.dataset
      if (lplantillaCuota.length > 0) {
        stepId = stepId + 1
        step = {
          id: stepId,
          title: 'Cuotas',
          subtitle: 'Cuotas incluidas en el presupuesto',
          formFields: [],
        }
        this.stepsPlantillas.push(step)
        for (const lineaPlantillaCuota of resp.data.selectLplantillaCuota.result.dataset) {
          const fieldnameLplantillaCuota = `lplantilla_cuota_${lineaPlantillaCuota.idlplantilla_cuota}`
          linea = {
            idlplantilla: lineaPlantillaCuota.idlplantilla_cuota,
            incluir: lineaPlantillaCuota.incluir_por_defecto,
            obligatorio: lineaPlantillaCuota.obligatorio,
            fieldnameLplantilla: fieldnameLplantillaCuota,
            title: this.$online.lplantillaCuota.title(lineaPlantillaCuota),
          }
          this.$set(this.form, fieldnameLplantillaCuota, lineaPlantillaCuota.incluir_por_defecto)
          step.formFields.push(linea)
        }
      }
      const lplantillaMant = resp.data.selectLplantillaMant.result.dataset
      if (lplantillaMant.length > 0) {
        stepId = stepId + 1
        step = {
          id: stepId,
          title: 'Revisiones',
          subtitle: 'Revisiones incluidas en el presupuesto',
          formFields: [],
        }
        this.stepsPlantillas.push(step)
        for (const lineaPlantillaMant of lplantillaMant) {
          const fieldnameLplantillaMant = `lplantilla_mant_${lineaPlantillaMant.idlplantilla_mant}`
          linea = {
            idlplantilla: lineaPlantillaMant.idlplantilla_mant,
            incluir: lineaPlantillaMant.incluir_por_defecto,
            obligatorio: lineaPlantillaMant.obligatorio,
            fieldnameLplantilla: fieldnameLplantillaMant,
            title: this.$online.lplantillaMant.title(lineaPlantillaMant),
          }
          this.$set(this.form, fieldnameLplantillaMant, lineaPlantillaMant.incluir_por_defecto)
          step.formFields.push(linea)
        }
      }
    },
    async changeTipoDatoStepFormData(data) {
      this.$set(this.form, 'datosPresupuestoCliente', data)
    },
  }
}
</script>

<style>
.v-stepper__label {
  text-shadow: none !important;
}
</style>
